 
<template>
  <b-row>
    <b-col md="4" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="2" sm="8" class="my-1">

    </b-col>
    <b-col md="6" class="my-1">
      <b-form-group label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(aduan)="data">
          <b-row>
            <b-col md="8">
              <small>Sub Kegiatan {{ data.item.id }}</small>
              <h6 class="text-primary"><small><strong>1 03 02 1,01 01 - </strong>
                  Penyusunan Rencana Teknis dan Dokumen Lingkungan Hidup untuk Konstruksi
                  Bendungan, Embung, dan Bangunan Penampung Air Lainnya
                </small></h6>
              <small>Lokasi</small>
              <h6 class="text-primary"><small><strong>OPD Provinsi Jawa Tengah - Kota Semarang </strong>
                </small></h6>
            </b-col>
            <b-col md="4">
              <small>Target / Satuan</small>
              <h4 class="text-primary"><strong>10</strong> <sub> Dokumen</sub></h4>
              <small>Anggaran</small>
              <h4 class="text-primary"><sup>Rp.</sup><strong>1.000.000.000,-</strong></h4>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 100,
      pageOptions: [15, 5, 10, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'aduan',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
      items: [
        {
          id: 1,
        }, {
          id: 1,
        }, {
          id: 1,
        }, {
          id: 1,
        },

      ],

    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
  },
  methods: {

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
 