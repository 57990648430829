<template>
  <div>
    <b-modal id="modal-sub-kegiatan" title="Detail Sub Kegiatan" ok-disabled no-close-on-backdrop hide-footer size="lg">
      <b-col>

        <b-row>
          <b-col md="12">
            <b-alert variant="info" show>
              <div class="alert-body">
                <b-row>
                  <b-col md="12">
                    <h6 class="text-primary"><small><strong>Pengajuan Kegiatan Tahun Anggaran 2024</strong></small></h6>
                    <b-row>
                      <b-col md="6">
                        <h6><small>Balai/Bidang</small></h6>
                        <h5 class="text-primary"><strong>BPJ Semarang</strong></h5>
                        <h6><small>Total Anggaran Sub Kegiatan</small></h6>
                        <h5 class="text-primary"><sup>Rp. </sup><strong>1.000.000.000,-</strong></h5>
                      </b-col>
                      <b-col>
                        <h6><small>Rincian Pengajuan</small></h6>
                        <b-row>
                          <b-col md="6">
                            <h5 class=text-success> <b-avatar rounded="" size="30" variant="light-success"
                                class=text-success>
                                <feather-icon icon="GridIcon" /></b-avatar> <strong>1</strong><small> Program</small></h5>

                            <h5 class=text-success><b-avatar rounded="" size="30" variant="light-success"
                                class=text-success>
                                <feather-icon icon="GridIcon" /></b-avatar> <strong>5</strong><small> Kegiatan</small>
                            </h5>
                          </b-col>
                          <b-col md="6">
                            <h5 class=text-success><b-avatar rounded="" size="30" variant="light-success">
                                <feather-icon icon="GridIcon" /></b-avatar> <strong>10</strong><small> Sub
                                Kegiatan</small>
                            </h5>
                          </b-col>
                        </b-row>


                      </b-col>
                    </b-row>
                  </b-col>

                </b-row>


              </div>
            </b-alert>
          </b-col>
          <b-col md="12">
            <h5><feather-icon icon="GridIcon" class="mr-50" size='15' /> Rincian Pengajuan Sub kegiatan</h5>
            <tabel-penganggaran></tabel-penganggaran>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">

          </b-col>
        </b-row>
      </b-col>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BCol, BRow, BAvatar } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TabelPenganggaran from './tabelPenganggaran.vue'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BCol, BRow,
    TabelPenganggaran, BAvatar
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-sub-kegiatan')
    }
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>